.featured_wrapper {
	@include media-breakpoint-down(md) {
		@include make-flex;
		flex-wrap: wrap;
		justify-content: center;
		> div {
			width: 100%;
		}
	}
	@include media-breakpoint-only(md) {
		> div {
			width: 49%;
			&:nth-child(even) { margin-left: auto; }
		}
	}
}


.abox_wrapper {
	padding: 0;
	@include media-breakpoint-up(sm) {
		@include make-flex;
		justify-content: space-evenly;
		.abox { width: 48%; }
	}
	@include media-breakpoint-only(md) {
		.abox { width: 32%; }
	}
	@include media-breakpoint-up(lg) {
		.abox { width: 100%; }
	}
	.page_front & {
		@include media-breakpoint-only(md) {
			//.abox:last-child { display: none; }
		}
		@include media-breakpoint-up(lg) {
			.abox { width: 48%; }
		}
	}
}


.abox {
	margin: 0 0 2%;
	padding: 15px;
	@include font-size(14);
	line-height: 1em;
	text-align: center;
	border: 1px solid darken($body-bg, 7.5%);
	background-color: $white;
	img {
		display: block;
		width: auto;
		max-height: 100px;
		margin: 0 auto;
	}
	.abox-title {
		display: block;
		//margin: 10px 0 5px;
		margin: 5px 0;
		//padding-top: 10px;
		@include font-size(16);
		line-height: 1em;
		letter-spacing: -0.0275em;
		//border-top: 1px dotted #ccc;
		small { display: block; }
	}
	.phone {
		@include font-size(17);
		line-height: 1em;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0425em;
		color: theme(secondary, base) !important;
	}
	p {
		margin-top: 10px;
		line-height: 1.275em;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}

}


